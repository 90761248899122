// Animation fade-in
@keyframes fadein {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.8;
  }
}

// Animation blur
@keyframes blur {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

// Animation blink
@keyframes blinker {  
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }
  
  100% {
    opacity: 1.0;
  }
}

// Responsive embed video
.embed-responsive {
  height: 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 20px;

  iframe,
  object,
  embed {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
