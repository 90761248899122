// Atatürk (aka. Father of Turks)
.ataturk {
  background-color: rgba(128, 128, 128, 0.1);
  border: 1px solid rgba(128, 128, 128, 0.1);
  margin: -2px 0 0 0;
  opacity: 0.7;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='42' viewport='0 0 100 100' style='fill:red;font-size:28px;'><text y='50%'>∞</text></svg>") 16 0,auto;
  @include media-query($on-mobile) {
    margin: 15px 0 -25px 0;
  }

  &:hover, &:focus, &:active {
    opacity: 1;
  }

  div {
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: -webkit-calc(#{$narrow-size} - (#{$spacing-full} * 2));
    max-width: calc(#{$narrow-size} - (#{$spacing-full} * 2));
    font-size: $small-font-size;
    padding: 7px 0;
    color: $dark-smoke;

    @include media-query($on-mobile) {
      padding: 8px $spacing-full;
    }

    img {
      width: 26px;
      margin: 0 8px 0 0;
    }

    span {
      margin-bottom: -2px;
    }
  }
}

// Navbar
.navbar {
  height: auto;
  max-width: -webkit-calc(#{$narrow-size} - (#{$spacing-full} * 2));
  max-width: calc(#{$narrow-size} - (#{$spacing-full} * 2));
  position: relative;
  margin: $spacing-half auto 0 auto;
  border-bottom: 1px solid $light;
  padding: $spacing-full - 15px 0;
  @extend %clearfix;
}

// Navigation
.menu {
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  a#mode {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.7;
    z-index: 1;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    &:focus {
      outline: 0;
    }
    &:active {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
    }
    .mode-moon {
      display: block;
      line {
        stroke: $black;
        fill: none;
      }

      circle {
        fill: $black;
        stroke: $black;
      }
    }
    .mode-sunny {
      display: none;
      line {
        stroke: $dark-white;
        fill: none;
      }
      circle {
        fill: none;
        stroke: $dark-white;
      }
    }
  }

  .trigger {
    float: left;
  }

  .trigger-right {
    display: flex;
    float: right;
    justify-content: center;
    align-items: center;
  }

  .menu-trigger {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  .menu-link {
    color: $black;
    line-height: $base-line-height + 0.4;
    text-decoration: none;
    padding: 5px 8px;
    opacity: 0.7;
    letter-spacing: 0.3px;

    &:hover {
      opacity: 1;
    }

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }

    &.rss {
      position: relative;
      bottom: -3px;
      outline: none;
      padding: 0;
      margin: 0 15px 0 0;

      > svg {
        fill: $black;
      }
    }

    @include media-query($on-mobile) {
      opacity: 0.9;
    }
  }

  .menu-link.active {
    opacity: 1;
    font-weight: 600;
  }

  @include media-query($on-mobile) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    text-align: center;
    height: 60px;
    background-color: $white;
    border-bottom: 1px solid $light;

    a#mode {
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px $spacing-full;
    }

    .menu-icon {
      display: block;
      position: absolute;
      right: 0;
      width: 50px;
      height: 23px;
      line-height: 0;
      padding: 18px $spacing-half;
      cursor: pointer;
      text-align: center;
      z-index: 1;
      > svg {
        fill: $dark-smoke;
        opacity: 0.8;
      }
      &:hover {
        > svg {
          opacity: 1;
        }
      }
      &:active {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
      }
      &:focus {
        outline: 0;
      }
    }

    input[type="checkbox"]:not(:checked) ~ .trigger {
      clear: both;
      visibility: hidden;
    }

    input[type="checkbox"]:checked ~ .trigger {
      position: fixed;
      animation: 0.2s ease-in forwards fadein;
      -webkit-animation: 0.2s ease-in forwards fadein;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $white;
      height: 100vh;
      width: 100%;
      top: 0;
    }

    .menu-link {
      display: block;
      box-sizing: border-box;
      font-size: 1.1em;

      &:not(:last-child) {
        margin: 0;
        padding: 2px 0;
      }

      &.rss {
        display: none;
      }
    }
  }
}

// Author
.author { 
  margin-top: 3.5rem;
  margin-bottom: 4.5rem;
  display: flex;
  align-items: flex-start;

  @include media-query($on-mobile) {
    margin-top: 4rem;
    flex-direction: column-reverse;
  }

  .author-avatar {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    user-select: none;
    background-color: $black;
    -ms-user-select: none;
    -webkit-user-select: none;
    -webkit-animation: 0.5s ease-in forwards fadein;
    animation: 0.5s ease-in forwards fadein;
    transition: all ease-in 0.2s;
    filter: grayscale(1);
    opacity: 1;

    &:hover {
      filter: grayscale(0);
      cursor: grabbing;
    }

    @include media-query($on-mobile) {
      width: 80px;
      height: 80px;
      margin: 0 0 $spacing-full 0;
    }
  }

  .author-name {
    font-size: 2.8em;
    line-height: 1;
    margin: 0;
    font-weight: 700;

    @include media-query($on-mobile) {
      font-size: 1.875rem;
    }
  }

  .author-title {
    font-size: 1em;
    line-height: $base-line-height;
    margin: 2px 0 10px 0;
    font-weight: 400;

    @include media-query($on-mobile) {
      font-size: $medium-font-size;
    }
  }

  .author-bio {
    padding-right: 3rem;
    margin: 0;
    opacity: 1;
    line-height: 1.4;
    font-size: $base-font-size;
  }
}

// Content
.posts-item-note {
  font-size: 1.8em;
  line-height: 1;
  font-weight: 700;
  margin-bottom: $spacing-half;
  color: $black;
  margin-top: 4.6rem;

  @include media-query($on-mobile) {
    font-size: 1.4rem;
  }
}

// Link
.posts-archive-link {
  display: inline-flex;
  align-items: center;
  color: #888888 !important;
  margin-top: 2rem;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: $dark-text-link-blue-active !important;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-left: 5px;
  }
}

// Medium list in homepage
.medium-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.6rem;

  @include media-query($on-mobile) {
    flex-wrap: wrap;
  }

  .medium-item {
    width: 30%;
    padding: 0.25rem;
    border-radius: 0.5rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    cursor: pointer;
    text-decoration: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -webkit-animation: 0.5s ease-in forwards fadein;
    animation: 0.5s ease-in forwards fadein;

    @include media-query($on-mobile) {
      width: 100%;
      margin-bottom: $spacing-half;
    }

    &:nth-child(1) {
      background-image: linear-gradient(to right, #d8b4fe, #707eff, #a2abfe);
    }

    &:nth-child(2) {
      background-image: linear-gradient(to right, #6ee7b7, #3b82f6, #9333ea);
    }

    &:nth-child(3) {
      background-image: linear-gradient(to right, #fde68a, #fca5a5, #fecaca);
    }
    
    &:hover, &:focus, &:active {
      transform: scaleX(1.02) scaleY(1.02);
      text-decoration: none;
      color: unset;
      outline: 0;
    }

    .medium-item-content {
      background: $white;
      padding: 1.1rem 1rem;
      border-radius: 0.5rem;

      h4 {
        font-size: 1.15rem;
        font-weight: 500;
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: $spacing-half;
        height: 80px;
        overflow: hidden;

        @include media-query($on-mobile) {
          height: auto;
        }
      }

      div {
        font-size: $small-font-size;
        margin-top: -6px;
        margin-bottom: -2px;
        opacity: 0.6;
        font-weight: 300;

        > svg {
          width: 12px;
          height: 12px;
          fill: $black;
          opacity: 0.8;
          margin-bottom: -1px;
        }
      }
    }
  }
}

// List of posts in homepage
.home-post-item {
  counter-increment: css-counter 1;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eaeaea;
  text-decoration: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;

  &:hover, &:focus, &:active {
    outline: 0;
    transform: scaleX(1.02) scaleY(1.02);
  }

  @include media-query($on-mobile) {
    flex-direction: column;
  }

  div {
    display: flex;
    align-items: center;
  }

  .home-post-item-order {
    @include media-query($on-mobile) {
      align-self: baseline;
    }
    
    &::before {
      font-size: $base-font-size;
      font-weight: normal;
      content: "0" counter(css-counter);
      width: 40px;
      display: inline-block;
      color: #888888;

      @include media-query($on-mobile) {
        font-size: $small-font-size;
      }
    }
  }

  .home-post-item-title {
    margin: 0;
    border: 0;
    padding: 0;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    letter-spacing: 0.1px;

    @include media-query($on-mobile) {
      font-size: $medium-font-size;
    }
  }

  .home-post-item-date {
    min-width: 96px;
    color: #888888;
    font-weight: normal;
    font-size: $base-font-size;

    @include media-query($on-mobile) {
      font-size: $small-font-size;
      margin-left: 40px;
    }
  }
}

// List of posts
.post-item {
  display: flex;
  padding-top: 5px;
  padding-bottom: 6px;
  @extend %clearfix;

  &:not(:first-child) {
    border-top: 1px solid $light;
  }

  .post-item-date {
    min-width: 96px;
    color: $black;
    font-weight: 700;
    padding-right: 10px;

    @include media-query($on-mobile) {
      font-size: 16px;
    }
  }

  .post-item-title {
    margin: 0;
    border: 0;
    padding: 0;
    font-size: $base-font-size;
    font-weight: normal;
    letter-spacing: 0.1px;

    a {
      color: $text-base-color;

      &:hover, &:focus, &:active {
        color: $black;
      }
    }
  }
}

// Spotify
.spotify {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  margin-top: 4.5rem;
  border-top: 1px solid #eaeaea;
  padding-top: 1.5rem;
  @include media-query($on-mobile) {
    flex-wrap: wrap;
  }

  svg {
    width: 1.1rem;
    height: 1.1rem;
  }

  span {
    font-size: 15px;
    margin-top: 2px;
    color: #999999;
  }

  #spotify-track-name {
    margin: 2px 5px 0 6px;
    color: $black;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(70%);

    @include media-query($on-mobile) {
      max-width: calc(100% - 95px);
    }

    > a {
      text-decoration: none;
      font-weight: 600;
      color: #d2d2d2;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

// Footer
.footer {
  margin-top: 1em;
  margin-bottom: 3em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    font-weight: normal;
    padding: 4px 0;
    color: $dark-smoke;
    opacity: 0.8;
    text-decoration: none;
    flex: 0 0 33.333%;
    @include media-query($on-mobile) {
      flex: 0 0 50%;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .footer_copyright {
    font-size: $small-font-size - 1;
    display: block;
    color: $smoke;
    font-size: 13px;
    margin-top: $spacing-half;
    flex: 100%;
    font-style: italic;

    &:after {
      content: '_';
      padding-left: 1px;
      -webkit-animation: 1s linear infinite blinker;
      animation: 1s linear infinite blinker;
    }
  }
}

// 404 page
.not-found {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 19px;

  .title {
    font-size: 5.5em;
    font-weight: $bold-weight;
    line-height: 1.1;
    color: $black;
    text-shadow: 1px 0px 0px $text-link-blue;
    margin-bottom: $spacing-full;
  }

  .phrase {
    color: $text-base-color;
    margin-bottom: $spacing-full;

    .gif {
      filter: brightness(0.4) blur(1px) opacity(0.8);
      z-index: -9;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .solution {
    color: $text-link-blue;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .solution:hover {
    color: $text-link-blue-active;
  }
}

// Search article
.search-article {
  position: relative;
  margin-bottom: 50px;

  label[for="search-input"] {
    position: relative;
    top: 10px;
    left: 11px;
  }

  input[type="search"] {
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 40px;
    outline: none;
    position: absolute;
    border-radius: 5px;
    padding: 10px 10px 10px 35px;
    color: $text-base-color;
    -webkit-appearance: none;
    font-size: $base-font-size;
    background-color: rgba(128, 128, 128, 0.1);
    border: 1px solid rgba(128, 128, 128, 0.1);
    &::-webkit-input-placeholder {
      color: #808080;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

// Search results
#search-results {
  text-align: center;
  li {
    text-align: left;
  }
}

// Archive tags
.archive-tags {
  height: auto;
  .tag-item {
    padding: 1px 3px;
    border-radius: 2px;
    border: 1px solid rgba(128, 128, 128, 0.1);
    background-color: rgba(128, 128, 128, 0.1);
  }
}